<template>
  <nav>
    <a
      id="download"
      href="./Katie_Adams_CV.pdf"
      download="Katie_Adams_CV.pdf"
      :class="{ lightNav: !darkMode }"
    >
      <span class="sr-only">Download my CV as a PDF</span>
      <DownloadIcon
        :colour="darkMode ? '#fff' : '#000'"
        height="32"
        width="32"
      />
    </a>
    <Transition name="slide-up" mode="out-in">
      <RouterLink
        v-if="path !== '/'"
        id="home"
        :class="{ lightNav: !darkMode }"
        to="/"
      >
        <span class="sr-only">Home</span>
        <TerminalIcon
          :colour="darkMode ? '#fff' : '#000'"
          height="32"
          width="32"
        />
      </RouterLink>
      <RouterLink
        v-else
        id="settings"
        :class="{ lightNav: !darkMode }"
        to="/settings"
      >
        <span class="sr-only">Settings</span>
        <SettingsIcon
          :colour="darkMode ? '#fff' : '#000'"
          height="32"
          width="32"
        />
      </RouterLink>
    </Transition>
  </nav>

  <router-view v-slot="{ Component }">
    <transition mode="out-in" name="fade">
      <component :is="Component" id="content" />
    </transition>
  </router-view>
</template>

<script lang="ts" setup>
import SettingsIcon from './components/icons/Settings.vue'
import TerminalIcon from './components/icons/Terminal.vue'
import DownloadIcon from './components/icons/Download.vue'
import { useRoute } from 'vue-router'
import { computed, onMounted, watch } from 'vue'
import { useSettingsStore } from './stores/settings'
const route = useRoute()

const settingsStore = useSettingsStore()

const darkMode = computed(() => settingsStore.settings.darkMode)

settingsStore.loadFromLocalStorage()

watch(
  () => darkMode.value,
  () => {
    checkDarkMode()
  }
)

function checkDarkMode() {
  if (!darkMode.value) {
    document.body.classList.add('light')
    document.getElementById('app')?.classList.add('light')
  } else {
    document.body.classList.remove('light')
    document.getElementById('app')?.classList.add('light')
  }
}

const path = computed(() => route.path)

onMounted(() => {
  checkDarkMode()
})
</script>

<style>
/* Global style variables */
:root {
  --primary-colour: #ffd970;
  --primary-colour-dark: #dcb959;
  --secondary-colour: #ff595e;
  --full-body-width: calc(100% - 16px);
  --nav-height: 45px;
  --bg-colour: #161617;
  --bg-colour-light: #f1f1f1;
  --scrollbar-width: 10px;
}

@font-face {
  font-family: 'VT323';
  src: url('./content/assets/fonts/VT323-Regular.ttf');
}

html,
#app {
  height: 100%;
}

body {
  background-color: var(--bg-colour);
  height: var(--full-body-width);
}

body.light {
  background-color: var(--bg-colour-light);
}

#app {
  letter-spacing: 1px;
  font-family: 'VT323', Georgia, 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: 0;
  bottom: 0;
  height: 98%;
}

#app.light {
  color: var(--bg-colour);
}

nav {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  padding-top: 4px;
  column-gap: 8px;
  width: var(--full-body-width);
  height: var(--nav-height);
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

#content {
  position: relative;
  height: calc(100% - var(--nav-height));
  width: 100%;
  margin-top: calc(var(--nav-height) + 10px);
}

#home,
#settings,
#download {
  border-color: #fff;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  display: inline-flex;
  aspect-ratio: 1/1;
  padding: 4px;
  justify-content: center;
  align-items: center;
}
#home.lightNav,
#settings.lightNav,
#download.lightNav {
  border-color: #000;
}

#home,
#settings.light #home img,
#settings img {
  display: block;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.sr-only {
  position: absolute;
  display: none;
}
</style>
