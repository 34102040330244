import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Terminal from '../views/TerminalView.vue'
import Settings from '../views/SettingsView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',

    name: 'terminal',
    component: Terminal,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
