<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<template>
  <div
    class="container"
    :class="{ light: !darkMode }"
    :style="`--text-size: ${textSize}px`"
  >
    <slot></slot>
    <span>
      <div v-for="label in valueLabels" :key="label" class="group">
        <label :class="{ light: !darkMode }" :for="`${groupId}-${label}`">
          {{ values[label] }}
          <input
            :id="`${groupId}-${label}`"
            type="radio"
            :name="groupId"
            :value="label"
            :checked="label === selected"
            @change="emitChange"
          />
          <span class="radio"></span>
        </label>
      </div>
    </span>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useSettingsStore } from '../stores/settings'

const settingsStore = useSettingsStore()
const textSize = computed(() => settingsStore.settings.textSize)
const darkMode = computed(() => settingsStore.settings.darkMode)

const props = defineProps({
  selected: {
    type: String,
    required: true,
  },
  values: {
    type: Object,
    required: true,
  },
  groupId: {
    type: String,
    required: true,
  },
})

const valueLabels = computed<string[]>(() => Object.keys(props.values))

const emit = defineEmits<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (e: 'change', value: any): void
}>()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function emitChange(e: any) {
  emit('change', e.target.value)
}
</script>

<style lang="postcss" scoped>
.container {
  width: 100%;
  color: white;
}

.container,
.container > span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container > span {
  column-gap: 20px;
}

.container.light {
  color: var(--bg-colour);
}

.group {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: calc(var(--text-size) + 6px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.group input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

label {
  display: block;
  padding: 4px 8px;
  cursor: pointer;
  border: white solid 2px;
  border-radius: 5px;
  transition: 0.4s;
}

label.light {
  border: var(--bg-colour) solid 2px;
}

label:hover {
  background: var(--secondary-colour);
}

.group input:checked ~ .radio {
  position: absolute;
  inset: 0;
  background-color: var(--secondary-colour);
  border-radius: 5px;
  z-index: -1;
}
</style>
