<template>
  <div class="terminal">
    <span class="gradient" :class="{ light: !darkMode }"></span>
    <TextDisplay />
    <ul v-if="!isDesktop || desktopShowButtons">
      <li v-for="command in commands" :key="`btn-` + command">
        <CustomButton @click="terminalStore.triggerCommand(command)">{{
          command
        }}</CustomButton>
      </li>
    </ul>
    <div v-else id="desktopInput">
      <span v-show="!isTyping">></span>
      <input
        id="input"
        ref="desktopInput"
        type="text"
        :style="`--text-size: ${textSize}px`"
        @keyup.enter="keyboardInput"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useTerminalStore } from '../stores/terminal'
import { useSettingsStore } from '../stores/settings'
import TextDisplay from '@/components/TextDisplay.vue'
import CustomButton from '@/components/Button.vue'
import _debounce from 'lodash.debounce'

const terminalStore = useTerminalStore()
const settingsStore = useSettingsStore()

const commands = computed(() => terminalStore.contentObjNames)
const isTyping = computed(() => terminalStore.isTyping)
const desktopShowButtons = computed(() => settingsStore.settings.desktopButtons)
const darkMode = computed(() => settingsStore.settings.darkMode)
const textSize = computed(() => settingsStore.settings.textSize)
const isDesktop = computed(() => windowWidth.value > 1024)

const desktopInput = ref<HTMLInputElement | undefined>(undefined)
const windowWidth = ref(window.innerWidth)

onMounted(() => {
  window.addEventListener('resize', debouncedListener)
})

onUnmounted(() => {
  window.removeEventListener('resize', debouncedListener)
})

const debouncedListener = _debounce(onResize, 25)

function onResize() {
  windowWidth.value = window.innerWidth
}

function keyboardInput(e: KeyboardEvent) {
  const event = e.target as HTMLInputElement
  terminalStore.triggerCommand(event.value)
  if (desktopInput.value) {
    desktopInput.value.value = ''
  }
}
</script>

<style lang="postcss" scoped>
ul {
  list-style: none;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px;
}

.terminal,
.gradient {
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.terminal {
  padding: 0px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.gradient {
  position: absolute;
  margin: 0px 40px;
  bottom: 90%;
  right: calc(40px + var(--scrollbar-width) - 20px);
  background: linear-gradient(
    to bottom,
    var(--bg-colour) 0,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 10;
}
.gradient.light {
  background: linear-gradient(
    to bottom,
    var(--bg-colour-light) 0,
    rgba(241, 241, 241, 0) 100%
  );
}

#desktopInput {
  display: flex;
  color: white;
  height: 30px;
  column-gap: 5px;
  align-items: center;
}

#desktopInput span {
  font-size: x-large;
}

#desktopInput #input {
  background: none;
  border: none;
  width: 100%;
  padding: 10px 0px;
  font-family: inherit;
  color: white;
  font-size: calc(6px + var(--text-size));
  text-transform: lowercase;
}

#desktopInput #input:focus {
  outline: none;
}
</style>
