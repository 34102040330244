import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { ContentInterface } from '@/types'
import { content } from '../content/content'

export const useTerminalStore = defineStore('terminal', () => {
  const isTyping = ref<boolean>(false)
  const newContent = ref<ContentInterface | undefined>(undefined)
  const currentCommand = ref('')

  const contentObjNames = computed(() =>
    Object.keys(content).sort((a, b) => {
      if (content[a].sortOrder < content[b].sortOrder) {
        return -1
      }
      if (content[a].sortOrder > content[b].sortOrder) {
        return 1
      }
      return 0
    })
  )

  function triggerCommand(enteredCommand: string) {
    const foundCommand = contentObjNames.value.find(
      (knownCommand) => knownCommand === enteredCommand
    )
    const output = foundCommand || undefined

    if (enteredCommand === 'help') {
      newContent.value = getHelpCommand()
    } else if (enteredCommand === 'kronos') {
      newContent.value = {
        content: `
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/goJjhyxWVgI?si=RFCFtyZfwGmDlAPI&amp;controls=0&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            `,
        options: {
          speed: 0,
        },
        sortOrder: 15,
      } as ContentInterface
    } else if (output === undefined) {
      newContent.value = {
        content: `
            <div style="text-transform:uppercase">Unrecognised command</div>
            `,
        options: {
          speed: 0,
        },
        sortOrder: 15,
      } as ContentInterface
    } else {
      currentCommand.value = output
      newContent.value = content[output]
    }
  }

  function typingStarted() {
    isTyping.value = true
  }

  function typingEnded() {
    isTyping.value = false
    currentCommand.value = ''
    resetContent()
  }

  function resetContent() {
    newContent.value = undefined
  }

  function getHelpCommand() {
    const listItems = Object.keys(content).map(
      (command) => '<li>' + command + '</li>'
    )
    return {
      content: `
          <div style="">
          Available commands: 
          <ul>
            ${listItems.join('')}
          </ul>
          </div>
          `,
      options: {
        speed: 0,
      },
      sortOrder: 15,
    } as ContentInterface
  }

  return {
    contentObjNames,
    triggerCommand,
    currentCommand,
    newContent,
    isTyping,
    typingStarted,
    typingEnded,
    resetContent,
  }
})
