<template>
  <label
    :class="{ light: !darkMode }"
    :for="id"
    class="container"
    :style="`--text-size: ${textSize}px`"
  >
    <slot></slot>
    <input :id="id" type="checkbox" :checked="checked" @change="emitChange" />
    <span class="checkmark" :class="{ light: !darkMode }"></span>
  </label>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useSettingsStore } from '../stores/settings'
const settingsStore = useSettingsStore()
const textSize = computed(() => settingsStore.settings.textSize)
const darkMode = computed(() => settingsStore.settings.darkMode)
defineProps({
  checked: {
    type: Boolean,
    required: true,
    default: false,
  },
  id: {
    type: String,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'change', value: boolean): void
}>()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function emitChange(e: any) {
  emit('change', e.target?.checked)
}
</script>

<style lang="postcss" scoped>
.container {
  display: block;
  position: relative;
  padding-left: 50px;
  cursor: pointer;
  color: #fff;
  font-size: calc(var(--text-size) + 6px);
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container.light {
  color: var(--bg-colour);
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  aspect-ratio: 1/1;
  background: none;
  border: white 2px solid;
  border-radius: 5px;
  transition: 0.3s;
}
.light.checkmark {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  aspect-ratio: 1/1;
  background: none;
  border: black 2px solid;
  border-radius: 5px;
  transition: 0.3s;
}

.container:hover input ~ .checkmark {
  background-color: #ffffff1b;
}

.container input:checked ~ .checkmark {
  background-color: var(--secondary-colour);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
  top: 40%;
  left: 17%;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

.light.container input:checked ~ .checkmark:after {
  display: block;
  top: 40%;
  left: 17%;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check-light 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1)
    forwards;
}

.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type='checkbox']:checked {
  transition: 0.3s;
}

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #fff;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #fff;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}

@keyframes checkbox-check-light {
  0% {
    width: 0;
    height: 0;
    border-color: #000;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #000;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}
</style>
