<template>
  <div
    class="settings"
    :class="{ light: !darkMode }"
    :style="`--text-size: ${textSize}px`"
  >
    <h1 class="title">Settings</h1>
    <ul>
      <li>
        <CustomCheckbox
          id="typingAnimation"
          :checked="typingAnimation"
          @change="disableTypingAnimation"
        >
          Disable typing animation?
        </CustomCheckbox>
      </li>
      <li :class="{ lightLabel: !darkMode }">
        <label>Text size</label>
        <span class="textSizeButtons">
          <CustomButton
            id="decreaseTextSize"
            :disabled="disableDecreaseButton"
            @click="changeTextSize(false)"
          >
            <label for="decreaseTextSize">Decrease</label>
          </CustomButton>
          <CustomButton
            id="increaseTextSize"
            :disabled="disableIncreaseButton"
            @click="changeTextSize(true)"
          >
            <label for="increaseTextSize">Increase</label>
          </CustomButton>
        </span>
      </li>
      <li>
        <CustomRadio
          group-id="typeSpeed"
          :values="speeds"
          :selected="typeSpeed"
          @change="setTypeSpeed"
          >Type speed</CustomRadio
        >
      </li>
      <li>
        <CustomCheckbox
          id="darkMode"
          :checked="darkMode"
          @change="disableDarkMode"
          >Enable dark mode?</CustomCheckbox
        >
      </li>
      <li>
        <CustomCheckbox
          id="desktopButtons"
          :checked="desktopButtons"
          @change="enableButtonsOnDesktop"
          >Enable buttons on desktop?</CustomCheckbox
        >
      </li>
      <li>
        <CustomButton :disabled="!unsavedChanges" @click="saveSettings()"
          >Save</CustomButton
        >
        <CustomButton :disabled="!changesToDefault" @click="resetSettings()"
          >Reset to default</CustomButton
        >
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import CustomCheckbox from '../components/Checkbox.vue'
import CustomButton from '../components/Button.vue'
import CustomRadio from '../components/Radio.vue'
import { useSettingsStore } from '@/stores/settings'
import { TypeSpeedMultipliers } from '@/types'
import { computed } from 'vue'

const settingsStore = useSettingsStore()

const typingAnimation = computed(
  () => settingsStore.settings.disableTypingAnimation
)
const textSize = computed(() => settingsStore.settings.textSize)
const disableIncreaseButton = computed(
  () => settingsStore.settings.textSize === 32
)
const disableDecreaseButton = computed(
  () => settingsStore.settings.textSize === 11
)
const darkMode = computed(() => settingsStore.settings.darkMode)
const desktopButtons = computed(() => settingsStore.settings.desktopButtons)
const typeSpeed = computed(() => {
  switch (settingsStore.settings.typeSpeed) {
    case TypeSpeedMultipliers.SLOW:
      return 'slow'
    case TypeSpeedMultipliers.MEDIUM:
      return 'medium'
    case TypeSpeedMultipliers.FAST:
      return 'fast'
    default:
      return 'medium'
  }
})

const unsavedChanges = computed(() => settingsStore.unsavedChanges)
const changesToDefault = computed(() => settingsStore.changesToDefault)

function disableTypingAnimation(value: boolean) {
  settingsStore.setDisableTypingAnimation(value)
}

function enableButtonsOnDesktop(value: boolean) {
  settingsStore.setDesktopButtons(value)
}

function disableDarkMode(value: boolean) {
  settingsStore.setDarkMode(value)
}

function changeTextSize(increase: boolean) {
  settingsStore.changeTextSize(increase)
}

function setTypeSpeed(speed: 'slow' | 'medium' | 'fast') {
  settingsStore.setTypeSpeed(speed)
}

function saveSettings() {
  settingsStore.saveToLocalStorage()
}

function resetSettings() {
  settingsStore.resetToDefault()
}

const speeds = {
  slow: 'Slow',
  medium: 'Medium',
  fast: 'Fast',
}
</script>

<style lang="postcss" scoped>
.settings {
  font-size: calc(var(--text-size));
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(white);
}
.settings.light {
  color: var(--bg-colour);
}
ul {
  padding: 0;
  text-align: left;
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  color: white;
}
li:last-child {
  margin-top: 32px;
}
.lightLabel {
  color: var(--bg-colour);
}
.options {
  display: flex;
}
.textSizeButtons {
  display: flex;
  justify-content: space-around;
  column-gap: 20px;
}
</style>
