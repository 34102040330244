import { defineStore } from 'pinia'
import { reactive, ref, watch } from 'vue'
import { SettingsInterface, TypeSpeedMultipliers } from '@/types'
export const useSettingsStore = defineStore('settings', () => {
  const settings = reactive<SettingsInterface>({
    disableTypingAnimation: false,
    textSize: 16,
    typeSpeed: 0,
    darkMode: true,
    desktopButtons: false,
  })
  const unsavedChanges = ref<boolean>(false)
  const changesToDefault = ref<boolean>(false)

  function setDisableTypingAnimation(value: boolean) {
    settings.disableTypingAnimation = value
  }

  function changeTextSize(increase: boolean) {
    if (increase && settings.textSize < 32) {
      settings.textSize++
    } else if (!increase && settings.textSize > 11) {
      settings.textSize--
    }
  }

  function setTypeSpeed(value: 'slow' | 'medium' | 'fast') {
    const speed = value.toUpperCase() as 'SLOW' | 'MEDIUM' | 'FAST'
    settings.typeSpeed = TypeSpeedMultipliers[speed]
  }

  function setDarkMode(value: boolean) {
    settings.darkMode = value
  }

  function setDesktopButtons(value: boolean) {
    settings.desktopButtons = value
  }

  function loadFromLocalStorage() {
    const ls = localStorage.getItem('settings')
    if (ls) {
      const s = JSON.parse(ls) as SettingsInterface
      settings.darkMode = s.darkMode
      settings.desktopButtons = s.desktopButtons
      settings.disableTypingAnimation = s.disableTypingAnimation
      settings.textSize = s.textSize
      settings.typeSpeed = s.typeSpeed
    }
    checkForChanges()
  }

  function saveToLocalStorage() {
    localStorage.setItem(
      'settings',
      JSON.stringify({
        disableTypingAnimation: settings.disableTypingAnimation,
        textSize: settings.textSize,
        typeSpeed: settings.typeSpeed,
        darkMode: settings.darkMode,
        desktopButtons: settings.desktopButtons,
      })
    )
    checkForChanges()
  }

  function resetToDefault() {
    settings.disableTypingAnimation = false
    settings.textSize = 16
    settings.typeSpeed = 0
    settings.darkMode = true
    settings.desktopButtons = false

    saveToLocalStorage()
  }

  watch(
    [
      () => settings.darkMode,
      () => settings.disableTypingAnimation,
      () => settings.textSize,
      () => settings.typeSpeed,
      () => settings.desktopButtons,
    ],
    () => {
      checkForChanges()
    }
  )

  function checkForChanges() {
    changesToDefault.value =
      settings.darkMode !== true ||
      settings.desktopButtons !== false ||
      settings.disableTypingAnimation !== false ||
      settings.textSize !== 16 ||
      settings.typeSpeed !== 0

    const ls = localStorage.getItem('settings')
    if (ls) {
      const s = JSON.parse(ls) as SettingsInterface
      unsavedChanges.value =
        settings.darkMode !== s.darkMode ||
        settings.desktopButtons !== s.desktopButtons ||
        settings.disableTypingAnimation !== s.disableTypingAnimation ||
        settings.textSize !== s.textSize ||
        settings.typeSpeed !== s.typeSpeed
    } else {
      unsavedChanges.value = changesToDefault.value
    }
  }

  return {
    settings,
    setDisableTypingAnimation,
    changeTextSize,
    setTypeSpeed,
    setDarkMode,
    setDesktopButtons,
    saveToLocalStorage,
    loadFromLocalStorage,
    resetToDefault,
    unsavedChanges,
    changesToDefault,
  }
})
