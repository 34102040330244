export enum TypeSpeedMultipliers {
  SLOW = -95,
  MEDIUM = 0,
  FAST = 25,
}

export interface ContentInterface {
  content: string
  options?: TypeItOptions
  sortOrder: number
}

export interface TypeItOptions {
  strings?: string | string[]
  speed?: number
  deleteSpeed?: number | null
  lifeLike?: boolean
  cursor?: boolean
  cursorSpeed?: number
  cursorChar?: string
  breakLines?: boolean
  nextStringDelay?: number | number[]
  waitUntilVisible?: boolean
  startDelay?: number
  loop: boolean
  loopDelay: number | number[]
  html: boolean
}

export interface SettingsInterface {
  disableTypingAnimation: boolean
  textSize: number
  typeSpeed: TypeSpeedMultipliers
  darkMode: boolean
  desktopButtons: boolean
}
