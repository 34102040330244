<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    ref="textbox"
    class="text"
    :class="{ light: !darkMode }"
    :style="`--text-size: ${textSize}px`"
  >
    <div id="typewriter" ref="typewriter"></div>
    <div id="history" ref="history"></div>
  </div>
</template>

<script lang="ts" setup>
import TypeIt from 'typeit'
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useTerminalStore } from '../stores/terminal'
import { useSettingsStore } from '../stores/settings'

const terminalStore = useTerminalStore()
const settingsStore = useSettingsStore()

const content = computed(() => terminalStore.newContent)
let typeItInstance: any

const textbox = ref<HTMLDivElement | undefined>(undefined)
const typewriter = ref<HTMLDivElement | undefined>(undefined)
const history = ref<HTMLDivElement | undefined>(undefined)
const cursor = ref<HTMLSpanElement>(document.createElement('span'))

// ⚙️ Settings ⚙️
const typingDisabled = computed(
  () => settingsStore.settings.disableTypingAnimation
)
const typeSpeed = computed(() => settingsStore.settings.typeSpeed)
const textSize = computed(() => {
  return settingsStore.settings.textSize - 16
})
const darkMode = computed(() => settingsStore.settings.darkMode)

function fixScrollbarToBottom() {
  if (textbox.value) {
    textbox.value.scrollTop = textbox.value.scrollHeight
  }
}

onMounted(() => {
  cursor.value?.insertAdjacentHTML('afterbegin', '|')
  terminalStore.triggerCommand('default')
})

onUnmounted(() => {
  terminalStore.resetContent()
  typeItInstance.destroy()
})

watch(
  () => content.value,
  () => {
    if (content.value !== undefined) {
      beginTyping()
    }
  }
)

function beginTyping() {
  fixScrollbarToBottom()

  if (typeItInstance) {
    typeItInstance.reset()
  }

  const speed = typingDisabled.value
    ? 0
    : 30 - typeSpeed.value - (content.value?.options?.speed || 0)

  typeItInstance = new TypeIt('#typewriter', {
    html: true,
    cursor: false,
    beforeString: async (characters: string[], instance: TypeIt) => {
      typewriter.value?.insertAdjacentElement('beforeend', cursor.value)
      instance.cursor.style['visibility'] = 'hidden'
      if (
        history.value &&
        history.value.innerText.length > 0 &&
        !terminalStore.isTyping
      ) {
        history.value?.insertAdjacentHTML(
          'beforeend',
          '<span class="break"></span>'
        )
      }
      terminalStore.typingStarted()
    },
    afterComplete: async (instance: TypeIt) => {
      cursor.value.remove()
      instance.cursor.style['visibility'] = ''
      addToHistory()
      terminalStore.typingEnded()
    },
  })

  typeItInstance
    .options({
      speed,
    })
    .type(content.value?.content)
    .go()
}

function addToHistory() {
  if (typewriter.value) {
    const cursorCopy = typewriter.value.getElementsByClassName('ti-cursor')[0]

    history.value?.insertAdjacentHTML('beforeend', typewriter.value.innerHTML)
    typewriter.value.innerHTML = ''
    typewriter.value.insertAdjacentElement('beforeend', cursorCopy)
  }
}
</script>

<style lang="postcss">
.text {
  position: relative;
  text-align: left;
  overflow: hidden;
  overflow-y: auto;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column-reverse;
  padding-right: 20px;
  box-sizing: border-box;
  color: white;
}
.text.light {
  color: black;
}

.text * {
  font-size: calc(16px + var(--text-size));
}
.text h1 {
  font-size: calc(32px + var(--text-size));
}

.text::-webkit-scrollbar {
  width: 10px;
}

.text::-webkit-scrollbar-track {
  background-color: #ffffff1c;
  border-radius: 100px;
}

.text::-webkit-scrollbar-thumb {
  background-color: var(--primary-colour);
  border-radius: 100px;
  box-shadow: inset -2px -2px 5px 2px var(--primary-colour-dark);
}

.text,
#typewriter,
#history {
  width: 100%;
}

h2 {
  margin-bottom: 8px;
}

.green {
  color: green;
}

a:link {
  color: rgb(172, 206, 255);
}

a:visited,
a:active {
  color: rgb(184, 114, 255);
}

img {
  max-width: 300px;
  border-radius: 25px;
  margin-bottom: 25px;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 5px 0px 3px 0px;
}

line {
  stroke: #fff;
  stroke-dasharray: 5;
}

.mt-0 {
  margin-top: 0;
}

#history span.ti-cursor {
  display: none;
}

#history {
  padding-top: 50px;
}

.break {
  display: block;
  width: 100%;
  height: 2px;
  margin: 35px 0px 33px 0px;
  border-radius: 15px;
  background: var(--primary-colour);
}
</style>
