<template>
  <button :class="{ light: !darkMode }" :style="`--text-size: ${textSize}px`">
    <slot></slot>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useSettingsStore } from '../stores/settings'
const settingsStore = useSettingsStore()
const textSize = computed(() => settingsStore.settings.textSize)
const darkMode = computed(() => settingsStore.settings.darkMode)
</script>

<style scoped>
@font-face {
  font-family: 'VT323';
  src: url('../content/assets/fonts/VT323-Regular.ttf');
}

button {
  font-family: 'VT323', Georgia, 'Times New Roman', Times, serif;
  font-size: calc(var(--text-size) + 4px);
  background: none;
  color: white;
  border: none;
  border: solid white 2px;
  border-radius: 5px;
  padding: 4px 8px;
  cursor: pointer;
  transition: 0.4s;
}
button.light {
  color: var(--bg-colour);
  border: solid var(--bg-colour) 2px;
}

button:hover {
  background: var(--secondary-colour);
}

button:disabled {
  opacity: 50%;
  cursor: not-allowed;
  background: none;
}
</style>
